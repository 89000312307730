"use client";
import { useEffect, useRef } from "react";

import { config, useAuth } from "@ctv/core";
import { isProduction } from "@ctv/core/config/env";
import { enabledRUMEnvs } from "@ctv/shared/constants/monitoring";
import { datadogRum } from "@datadog/browser-rum";

const ignoredErrors = [
  /Object Not Found Id:2, MethodName:update, ParamCount:4/i,
  /Uncaught "Empty reason"/i,
];
const ignoredErrorsOnProduction = [/csp_violation/i];

export default function DatadogInitializer() {
  const { user } = useAuth();
  const userRef = useRef(user);

  useEffect(() => {
    if (!user) return;
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    // Only initialize Datadog RUM for enabled environments.
    if (!config.datadog.env || !enabledRUMEnvs.includes(config.datadog.env)) {
      return;
    }

    // Prevent multiple initialization, expected to be initialized once.
    if (datadogRum.getInitConfiguration()) {
      return;
    }

    try {
      datadogRum.init({
        ...config.datadog,
        beforeSend: (event, _context) => {
          // Filter out user data from action event.
          if (event.type === "action") {
            // Filter data if needed.
            if (event.action.target?.name && userRef.current) {
              const targetNameArr = event.action.target.name.toLowerCase();
              const filteredWords = [
                userRef.current.email,
                userRef.current.corporateCode,
                userRef.current.corporateName,
                userRef.current.corporateAlias,
                userRef.current.name,
              ]
                .filter(Boolean)
                .map((word) => word.toLowerCase());

              if (
                filteredWords?.some((filteredWord) =>
                  targetNameArr.includes(filteredWord)
                )
              ) {
                event.action.target.name = "[REDACTED]";
              }
            }
          } else if (event.type === "error") {
            if (
              ignoredErrorsOnProduction.some((err) =>
                event.error.message.match(err)
              )
            ) {
              return !isProduction;
            }

            if (ignoredErrors.some((err) => event.error.message.match(err))) {
              return false;
            }
          }

          return true;
        },
      });
    } catch (error) {
      // No op.
    }
  }, []);

  return null;
}
